const GroupAPI = {
  baseURL: "https://api.justblink.com.pl",
  //baseURL: "http://localhost:3001",

  getGroup: (): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(`${GroupAPI.baseURL}/groups`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    });
  },
  createInvitation: (email: string, collectionId: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(`${GroupAPI.baseURL}/groups/invitations`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        memberEmail: email,
        collectionId: collectionId,
      }),
    });
  },
  leaveGroup: (groupId: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(`${GroupAPI.baseURL}/groups/${groupId}/invitations`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    });
  },
  unsubscribeCollection: (groupId: string, collectionId: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(`${GroupAPI.baseURL}/groups/${groupId}/collections/${collectionId}`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    });
  },
};

export default GroupAPI;
