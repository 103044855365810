/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useContext, FC } from "react";
import { Collection, Collections } from "./CollectionModel";
import { Group, Groups } from "./GroupModel";
import { ErrData } from "./ErrorModel";
import GroupAPI from "../api/GroupAPI";
import CollectionAPI from "../api/CollectionAPI";
import { LABELS_I18N } from "../../common/i18n/pl_PL";

interface UserAssetContextProps {
  collections: Collections;
  groups: Groups;
  pickedCollection: Collection;
  populatePickedCollection: (collectionId: string) => void;
  ownGroup: Group;
  error: ErrData;
  setError: (error: ErrData) => void;
  setUserAssets: () => void;
  refreshUserAssets: () => void;
  clearUserAsset: () => void;
  isUserAssetsFetched: boolean;
  isUserAssetsManagement: boolean;
  setIsUserAssetsManagement: (isUserAssetsManagement: boolean) => void;
  openManagementPage: () => void;
  closeManagementPage: () => void;
  deleteOwnedCollection: (collectionId: string) => void;
  deleteCollectionSubscription: (groupId: string, collectionId: string) => void;
  createCollection: (collectionName: string, collectionLabel: string) => void;
  getCollectionForEdit: (collectionId: string) => Promise<Collection>;
  updateCollectionDetails: (
    editedCollectionId: string,
    editedCollectionName: string,
    editedCollectionLabel: string,
    editedTemplateName?: string,
    editedTemplateText?: string
  ) => void;
  isUpdateLoading: boolean;
  inviteCollection: (collectionId: string, email: string[]) => void;
}

export const UserAssetContext = createContext<UserAssetContextProps>({
  collections: null,
  groups: null,
  pickedCollection: null,
  populatePickedCollection: () => {},
  ownGroup: null,
  error: null,
  setError: () => {},
  setUserAssets: () => {},
  refreshUserAssets: () => {},
  clearUserAsset: () => {},
  isUserAssetsFetched: false,
  isUserAssetsManagement: false,
  setIsUserAssetsManagement: () => {},
  openManagementPage: () => {},
  closeManagementPage: () => {},
  deleteOwnedCollection: () => {},
  deleteCollectionSubscription: () => {},
  createCollection: () => {},
  getCollectionForEdit: () => null,
  updateCollectionDetails: () => {},
  isUpdateLoading: false,
  inviteCollection: () => {},
});

type Props = {
  children?: React.ReactNode;
};

const DEFAULT_COLLECTION_TEMPLATE = {
  metadata: {
    collectionName: "Just Blink ",
    ownerId: "JustBlink",
    uiprops: {
      label: "Domyślna kolekcja Just Blink",
    },
  },
  id: "default",
};

export const UserAssetProvider: FC<Props> = ({ children }) => {
  const [pickedCollection, setPickedCollection] = useState<Collection>(DEFAULT_COLLECTION_TEMPLATE);
  const [collections, setCollections] = useState<Collections>([]);
  const [groups, setGroups] = useState<Groups>([]);
  const [ownGroup, setOwnGroup] = useState<Group>(null);
  const [error, setError] = useState<ErrData | null>(null);
  const [isUserAssetsFetched, setisUserAssetsFetched] = useState<boolean>(false);
  const [isUserAssetsManagement, setIsUserAssetsManagement] = useState<boolean>(false);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState<boolean>(false);

  const createCollection = async (collectionName: string, collectionLabel: string) => {
    setIsUpdateLoading(true);
    CollectionAPI.createCollection(collectionName, collectionLabel)
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then(() => {
        refreshUserAssets();
      })
      .catch((err) => {
        setError({
          code: err.status,
          message: err.message,
        });
      })
      .finally(() => {
        setIsUpdateLoading(false);
      });
  };

  const getCollectionForEdit = async (collectionId: string) => {
    let collectionDetails: Collection = await CollectionAPI.getCollectionDetails(collectionId)
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 200) throw new Error(LABELS_I18N.error.collection);
        return res.data.then((cDetails: Collection) => {
          collectionDetails = cDetails;
          return collectionDetails;
        });
      });
    return collectionDetails;
  };

  const setUserAssets = async () => {
    await GroupAPI.getGroup()
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((groupResponse) => {
        if (groupResponse.status != 200) throw Error(LABELS_I18N.error.group);
        groupResponse.data
          .then((groups) => {
            if (groups.length > 0) {
              setGroups(groups);
              let group = groups.filter((item: Group) => typeof item.ownCollections !== "undefined").flat();
              group && setOwnGroup(group);
            }
            return groups;
          })
          .then((groups: Groups) => {
            let subscriberdCollectionList: string[] = [];
            if (groups.length > 0) {
              groups.flatMap((item: Group) => {
                item?.ownCollections && subscriberdCollectionList.push(...item.ownCollections);
                //groups contain only data concerning calling member on own
                item?.members.length > 0 && subscriberdCollectionList.push(...item.members[0].collections);
                return subscriberdCollectionList.flat();
              });
            }
            return subscriberdCollectionList;
          })
          .then((subscriberdCollectionList) => {
            CollectionAPI.searchCollection(subscriberdCollectionList)
              .then((response) => {
                return {
                  data: response.json(),
                  status: response.status,
                };
              })
              .then((collectionResponse) => {
                if (collectionResponse.status != 201) {
                  throw Error("Could not fetch collection data");
                } else {
                  return collectionResponse.data.then((collections) => {
                    setCollections(collections);
                  });
                }
              });
          });
      })
      .then(() => {
        setisUserAssetsFetched(true);
        setPickedCollection(DEFAULT_COLLECTION_TEMPLATE);
        //setError(null);
        return;
      })
      .catch((err) => {
        setisUserAssetsFetched(false);
        setCollections(null);
        setGroups(null);
        setOwnGroup(null);
        setPickedCollection(null);
        setError({
          code: err.status,
          message: err.message,
        });
      });
  };

  const refreshUserAssets = async () => {
    await GroupAPI.getGroup()
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((groupResponse) => {
        if (groupResponse.status != 200) throw Error(LABELS_I18N.error.group);
        groupResponse.data
          .then((groups) => {
            if (groups.length > 0) {
              setGroups(groups);
              let group = groups.filter((item: Group) => typeof item.ownCollections !== "undefined").flat();
              group && setOwnGroup(group);
            }
            return groups;
          })
          .then((groups: Groups) => {
            let subscriberdCollectionList: string[] = [];
            if (groups.length > 0) {
              groups.flatMap((item: Group) => {
                item?.ownCollections && subscriberdCollectionList.push(...item.ownCollections);
                //groups contain only data concerning calling member on own
                item?.members.length > 0 && subscriberdCollectionList.push(...item.members[0].collections);
                return subscriberdCollectionList.flat();
              });
            }
            return subscriberdCollectionList;
          })
          .then((subscriberdCollectionList) => {
            CollectionAPI.searchCollection(subscriberdCollectionList)
              .then((response) => {
                return {
                  data: response.json(),
                  status: response.status,
                };
              })
              .then((collectionResponse) => {
                if (collectionResponse.status != 201) {
                  throw Error("Could not fetch collection data");
                } else {
                  return collectionResponse.data.then((collections) => {
                    setCollections(collections);
                  });
                }
              });
          });
      })
      .then(() => {
        setisUserAssetsFetched(true);
        if (collections.findIndex((item) => item.id == pickedCollection.id) == -1) {
          setPickedCollection(DEFAULT_COLLECTION_TEMPLATE);
        }
        //setError(null);
        return;
      })
      .catch((err) => {
        setisUserAssetsFetched(false);
        setCollections(null);
        setGroups(null);
        setOwnGroup(null);
        setPickedCollection(null);
        setError({
          code: err.status,
          message: err.message,
        });
      });
  };

  const deleteOwnedCollection = async (collectionId: string) => {
    setIsUpdateLoading(true);
    await CollectionAPI.deleteCollection(collectionId)
      .then((response) => {
        return {
          status: response.status,
        };
      })
      .then((collectionResponse) => {
        if (collectionResponse.status != 204) {
          throw Error("Could not fetch collection data");
        } else {
          refreshUserAssets();
        }
      })
      .catch()
      .finally(() => {
        setIsUpdateLoading(false);
      });
  };
  const deleteCollectionSubscription = async (groupId: string, collectionId: string) => {
    await GroupAPI.unsubscribeCollection(groupId, collectionId)
      .then((response) => {
        return {
          status: response.status,
        };
      })
      .then((collectionResponse) => {
        if (collectionResponse.status != 204) {
          throw Error("Could not fetch collection data");
        } else {
          refreshUserAssets();
        }
      })
      .catch()
      .finally(() => {
        setIsUpdateLoading(false);
      });
  };

  const clearUserAsset = async () => {
    setisUserAssetsFetched(false);
    setCollections(null);
    setGroups(null);
    setOwnGroup(null);
    setPickedCollection(null);
    setError(null);
  };

  const openManagementPage = () => {
    setIsUserAssetsManagement(true);
  };

  const closeManagementPage = () => {
    setIsUserAssetsManagement(false);
  };

  const populatePickedCollection = async (collectionId: string) => {
    let collectionDetails: Collection = await CollectionAPI.getCollectionDetails(collectionId)
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 200) throw new Error(LABELS_I18N.error.collection);
        return res.data.then((cDetails: Collection) => {
          collectionDetails = cDetails;
          return collectionDetails;
        });
      });
    setPickedCollection(collectionDetails);
  };

  const updateCollectionDetails = async (
    editedCollectionId: string,
    editedCollectionName: string,
    editedCollectionLabel: string,
    editedTemplateName?: string | boolean,
    editedTemplateText?: string | boolean
  ) => {
    setIsUpdateLoading(true);
    await CollectionAPI.updateCollection(editedCollectionId, editedCollectionName, editedCollectionLabel)
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 204) throw new Error(LABELS_I18N.error.collectionUpdate);
        if (res.status === 204) {
          refreshUserAssets()
            .then(() => {
              setIsUpdateLoading(false);
            })
            .finally(() => setIsUpdateLoading(false));
        }
      })
      .catch((err) => {
        setError({ code: 500, message: err.message });
      });

    if (editedTemplateName || editedTemplateText) {
      await CollectionAPI.updateCollectionTemplate(
        editedCollectionId,
        editedTemplateName.toString(),
        editedTemplateText.toString()
      )
        .then((response) => {
          return {
            data: response.json(),
            status: response.status,
          };
        })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(LABELS_I18N.error.collectionTemplateUpdate);
          } else {
            refreshUserAssets()
              .then(() => {
                setIsUpdateLoading(false);
              })
              .finally(() => setIsUpdateLoading(false));
          }
        })
        .catch((err) => {
          setError({ code: 500, message: err.message });
        })
        .finally(() => {
          setIsUpdateLoading(false);
        });
    }
  };

  const inviteCollection = async (collectionId: string, email: string[]) => {
    setIsUpdateLoading(true);
    const DEFAULT_REFRESH_DELAY = 300; //miliseconds
    let counter = 0;
    email.forEach((emailItem) => {
      GroupAPI.createInvitation(emailItem, collectionId)
        .then((response) => {
          return {
            data: response.json(),
            status: response.status,
          };
        })
        .then((res) => {
          if (res.status !== 201) throw new Error(LABELS_I18N.error.group);
        });
      counter++;
    });
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      refreshUserAssets()
        .then(() => {
          setIsUpdateLoading(false);
        })
        .finally(() => {
          setIsUpdateLoading(false);
        });
    }, counter * DEFAULT_REFRESH_DELAY);
  };
  return (
    <UserAssetContext.Provider
      value={{
        collections,
        groups,
        pickedCollection,
        populatePickedCollection,
        ownGroup,
        error,
        setError,
        setUserAssets,
        refreshUserAssets,
        clearUserAsset,
        isUserAssetsFetched,
        isUserAssetsManagement,
        setIsUserAssetsManagement,
        openManagementPage,
        closeManagementPage,
        deleteOwnedCollection,
        deleteCollectionSubscription,
        createCollection,
        getCollectionForEdit,
        updateCollectionDetails,
        isUpdateLoading,
        inviteCollection,
      }}
    >
      {children}
    </UserAssetContext.Provider>
  );
};

export const useUserAsset = (): UserAssetContextProps => useContext(UserAssetContext);
