import React from "react";
import { TextField, PrimaryButton, DefaultButton, Stack, IStackTokens } from "@fluentui/react";
import { LABELS_I18N } from "../common/i18n/pl_PL";
interface CollectionInviteComponentProps {
  inviteCollectionId: string;
  onCancel?: () => void;
  onSubmit?: (invitedCollectionId: string, inviteEmail: string[], isError?: boolean) => void;
}

const stackTokens: IStackTokens = { childrenGap: 20 };

const CollectionInviteComponent: React.FC<CollectionInviteComponentProps> = ({
  inviteCollectionId,
  onCancel,
  onSubmit,
}) => {
  const [currentCollectionId, setCurrentCollectionId] = React.useState<string>("");
  const [inviteEmail, setInviteEmail] = React.useState<string>("");
  const [inviteEmailList, setInviteEmailList] = React.useState<string[]>([]);
  const [isInviteEmailError, setisInviteEmailError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setCurrentCollectionId(inviteCollectionId);
  }, []);

  const handleInviteEmailChange = (_e, newValue?: string) => {
    if (!newValue.match(/[\w-_.]+@([\w-]+\.)+[\w-]{2,4}/g)) {
      setisInviteEmailError(true);
    } else {
      setisInviteEmailError(false);
    }
    let emailArr = newValue.match(/[\w-_.]+@([\w-]+\.)+[\w-]{2,4}/g);
    setInviteEmailList(emailArr);
    setInviteEmail(newValue || "");
  };

  return (
    <div>
      <TextField
        label={LABELS_I18N.collectionInviteComponent.inviteEmailLabel}
        onChange={handleInviteEmailChange}
        value={inviteEmail}
        onGetErrorMessage={() => {
          return isInviteEmailError ? `${LABELS_I18N.error.email}` : "";
        }}
        multiline
      />
      <Stack
        horizontal
        tokens={stackTokens}
        verticalAlign="end"
        style={{ padding: "10px 10px 10px 10px", margin: "10px 10px 10px 10px" }}
      >
        <Stack.Item>
          <PrimaryButton
            text={LABELS_I18N.general.submit}
            onClick={() => onSubmit(currentCollectionId, inviteEmailList, isInviteEmailError)}
            disabled={isInviteEmailError}
          />
        </Stack.Item>
        <Stack.Item>
          <DefaultButton text={LABELS_I18N.general.cancel} onClick={() => onCancel()} />
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default CollectionInviteComponent;
