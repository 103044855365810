import { API } from "./AxiosConfig";
import { Organization } from "../organization/OrganizationModel";
import { formatDate } from "../../common/utils/formatting";

const OrganizationsAPI = {
  getOrganization: (krs: string, date?: Date) => {
    if (date) {
      return API.get<Organization>(`/organisations/${krs}`, { params: { date: formatDate(date) } }).then(
        (res) => res.data
      );
    } else {
      return API.get<Organization>(`/organisations/${krs}`).then((res) => res.data);
    }
  },
};

export default OrganizationsAPI;
