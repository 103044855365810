import React, { Fragment, useEffect, useState } from "react";
import {
  DatePicker,
  Label,
  PrimaryButton,
  TextField,
  Checkbox,
  Stack,
  IStackTokens,
  FontIcon,
  TooltipHost,
  TooltipDelay,
} from "@fluentui/react";
import { useId } from "@fluentui/react-components";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { formatDate } from "../../common/utils/formatting";
import { i18nDatePicker, LABELS_I18N } from "../../common/i18n/pl_PL";

interface SearchProps {
  onSearch: (search: string, selectedDate?: Date) => void;
  onDatePickerStateChange: () => void;
  query: string;
  queryDate: Date;
  disabled: boolean;
}
const OrganizationSearchGus: React.FC<SearchProps> = ({
  onSearch,
  onDatePickerStateChange,
  query,
  queryDate,
  disabled,
}) => {
  const [krs, setKrs] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [krsError, setKrsError] = useState<string>("");
  const [datePickerDisabled, setDatePickerDisabled] = useState<boolean>(true);
  const { isAuthorized } = useAuthorization();
  const stateTooltipId = useId("stateTooltip");

  const KRS_ERROR_MESSAGE = `${LABELS_I18N.organizationSearch.krsErrorMessage}`;

  useEffect(() => {
    setKrs(query);
    setSelectedDate(queryDate);
  }, [query, queryDate]);

  useEffect(() => {
    if (!isAuthorized) {
      setKrsError("");
      setKrs("");
      setSelectedDate(undefined);
      setDatePickerDisabled(true);
    }
  }, [isAuthorized]);

  const containsOnlyNumbers = (string: string): boolean => {
    return /^\d+$/.test(string);
  };

  const isValidInput = (): boolean => {
    if (!containsOnlyNumbers(krs)) return false;
    return true;
  };

  // @ts-ignore
  const handleKrsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setKrs(newValue || "");
  };

  const handleSearchClick = () => {
    if (isValidInput()) {
      setKrsError("");
      onSearch(krs, selectedDate);
    } else {
      setKrsError(KRS_ERROR_MESSAGE);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  function handleClearCheckbox(_event?: any, checked?: boolean): void {
    onDatePickerStateChange();
    if (checked == false) {
      setDatePickerDisabled(true);
      setSelectedDate(undefined);
    } else {
      setDatePickerDisabled(false);
    }
  }

  const datePickerTokens: IStackTokens = {
    childrenGap: 2,
    maxWidth: "60%",
  };
  const stateTokens: IStackTokens = {
    childrenGap: 2,
    maxWidth: "120px",
  };

  return (
    <div>
      <Label className="ms-fontSize-18">Wyszukaj podmiot</Label>
      <TextField
        onChange={handleKrsChange}
        onKeyDown={handleKeyDown}
        value={krs}
        placeholder={`${LABELS_I18N.organizationSearch.textField.placeholder.part3}`}
      />
      {isAuthorized && (
        <Fragment>
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={stateTokens}>
            <Stack.Item>
              <Label>Stan na dzień: </Label>
            </Stack.Item>
            <Stack.Item>
              <TooltipHost
                id={stateTooltipId}
                delay={TooltipDelay.zero}
                content={LABELS_I18N.organizationSearch.toolTip.date}
              >
                <FontIcon iconName="info" aria-describedby={stateTooltipId}></FontIcon>
              </TooltipHost>
            </Stack.Item>
          </Stack>
          <Stack horizontal reversed horizontalAlign="space-between" verticalAlign="center" tokens={datePickerTokens}>
            <Stack.Item grow={8}>
              <DatePicker
                ariaLabel={LABELS_I18N.organizationSearch.datePicker.ariaLabel}
                value={selectedDate}
                onSelectDate={setSelectedDate as (date: Date) => void}
                formatDate={formatDate}
                disabled={datePickerDisabled}
                strings={i18nDatePicker}
                firstDayOfWeek={1}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <Checkbox onChange={handleClearCheckbox} checked={!datePickerDisabled} />
            </Stack.Item>
          </Stack>
        </Fragment>
      )}
      <div className="ms-fontColor-sharedRed20">{krsError}</div>
      <div className="blink-actions">
        <PrimaryButton
          text={LABELS_I18N.organizationSearch.primaryButton.text}
          onClick={handleSearchClick}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default OrganizationSearchGus;
