const TAG_NAME_MAP = new Map();
TAG_NAME_MAP.set("nazwa", "companyName");
TAG_NAME_MAP.set("NIP", "nipId");
TAG_NAME_MAP.set("KRS", "krsId");
TAG_NAME_MAP.set("REGON", "regonId");
TAG_NAME_MAP.set("miejscowość", "companyAddressCity");
TAG_NAME_MAP.set("siedziba", "companyHeadquaterCity");
TAG_NAME_MAP.set("kraj", "companyAddressCountry");
TAG_NAME_MAP.set("ulica", "companyAddressStreet");
TAG_NAME_MAP.set("numer", "companyAddressStreetNumber");
TAG_NAME_MAP.set("lokal", "companyAddressApartment");
TAG_NAME_MAP.set("kod pocztowy", "companyAddressPostalCode");
TAG_NAME_MAP.set("sąd rejestrowy", "courtOfLaw");
TAG_NAME_MAP.set("kapitał", "companyFund");
TAG_NAME_MAP.set("waluta", "companyFundCurrency");
TAG_NAME_MAP.set("kapitał opłacony", "companyFundPaid");
TAG_NAME_MAP.set("waluta opłacona", "companyFundPaidCurrency");
TAG_NAME_MAP.set("czy duży podmiot", "companyScale");
TAG_NAME_MAP.set("czy przedsiębiorstwo", "companyRegisterFlag");
TAG_NAME_MAP.set("imię", "companyOwnerFirstName");
TAG_NAME_MAP.set("nazwisko", "companyOwnerLastName");

export interface TemplateTagValidity {
  isValid: boolean;
  tagName?: string;
}

const REGEX_RAW_TAGS = new RegExp("\\[\\[([^\\]]+)\\]\\]", "g");
export const validateTagName = (usedTags) => {
  let result: TemplateTagValidity = { isValid: true };
  if (Array.isArray(usedTags)) {
    usedTags.forEach((item) => {
      if (typeof TAG_NAME_MAP.get(item) == "undefined") {
        result.tagName = item;
        result.isValid = false;
        return result;
      }
      return result;
    });
  }
  return result;
};

export const validateAndReturnTemplate = (templateText: string) => {
  let resultTemplateText = replaceTemplateChunks(getRawTags(templateText), templateText);
  return resultTemplateText;
};

function replaceTemplateChunks(rawTags, templateText) {
  let resultSubstMap = new Map();
  rawTags.forEach((entry) => {
    if (entry.includes("++")) {
      let templateSubstTagKey = TAG_NAME_MAP.get(getSubstitutionMap([entry]).keys().next().value);
      let prefix = entry.match(/(?<=\+\+).*?(?=\+\+)/);
      //{{#TagName}} lok. {{TagName}}{{/TagName}}
      const SUBSTITUTION_TEMPLATE = `{{#${templateSubstTagKey}}}${prefix}{{${templateSubstTagKey}}}{{/${templateSubstTagKey}}}`;
      resultSubstMap.set(entry, SUBSTITUTION_TEMPLATE);
    } else if (entry.includes("!!")) {
      let templateSubstTagKey = TAG_NAME_MAP.get(getSubstitutionMap([entry]).keys().next().value);
      let alternativeText = entry.match(/(?<=!!).*?(?=!!)/);
      //{{^TagName}} alternatywny {{/TagName}}
      const SUBSTITUTION_TEMPLATE = `{{^${templateSubstTagKey}}}${alternativeText}{{/${templateSubstTagKey}}}`;
      resultSubstMap.set(entry, SUBSTITUTION_TEMPLATE);
    } else if (entry.includes("/")) {
      let templateSubstTagKey = TAG_NAME_MAP.get(getSubstitutionMap([entry]).keys().next().value);
      let altTag = entry.match(/(?<=\/).*?(?=\]\])/);
      if (typeof TAG_NAME_MAP.get(altTag[0]) == "undefined") {
        let altTagText = altTag[0];
        //{{#TagName}}{{TagName}}{{/TagName}}{{^TagName}} alternatywny {{/TagName}}
        const SUBSTITUTION_TEMPLATE = `{{#${templateSubstTagKey}}}{{${templateSubstTagKey}}}{{/${templateSubstTagKey}}}{{^${templateSubstTagKey}}}${altTagText}{{/${templateSubstTagKey}}}`;
        resultSubstMap.set(entry, SUBSTITUTION_TEMPLATE);
      } else {
        let altTagName = TAG_NAME_MAP.get(altTag[0]);
        //{{#TagName}}{{TagName}}{{/TagName}}{{^TagName}}{{AltTagName}}{{/TagName}}
        const SUBSTITUTION_TEMPLATE = `{{#${templateSubstTagKey}}}{{${templateSubstTagKey}}}{{/${templateSubstTagKey}}}{{^${templateSubstTagKey}}}{{${altTagName}}}{{/${templateSubstTagKey}}}`;
        resultSubstMap.set(entry, SUBSTITUTION_TEMPLATE);
      }
    } else {
      let templateSubstTagKey = TAG_NAME_MAP.get(getSubstitutionMap([entry]).keys().next().value);
      //{{#TagName}}{{TagName}}{{/TagName}}
      const SUBSTITUTION_TEMPLATE = `{{#${templateSubstTagKey}}}{{${templateSubstTagKey}}}{{/${templateSubstTagKey}}}`;
      resultSubstMap.set(entry, SUBSTITUTION_TEMPLATE);
    }
  });

  resultSubstMap.forEach((v, k) => {
    templateText = templateText.replace(v, k);
  });
  return templateText;
}

function getSubstitutionMap(rawTags) {
  let substMap = new Map();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TAG_NAME_MAP.forEach((v, _k) => {
    let currentRawTag = rawTags.filter((item) => item.includes(v) && !item.includes(`/${v}`)).shift();
    currentRawTag && substMap.set(v, currentRawTag);
  });

  return substMap;
}

function getRawTags(templateText) {
  return templateText.match(REGEX_RAW_TAGS);
}

export const getUsedTags = (templateText) => {
  const regexBackToFront = new RegExp(
    "[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż0-9\\_\\-\\ ]+$"
  );
  const regexFrontToBack = new RegExp(
    "^[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż0-9\\_\\-\\ ]+"
  );
  let tokens = templateText.match(REGEX_RAW_TAGS);
  if (Array.isArray(tokens)) {
    tokens = tokens.flatMap((item) => {
      item = item.slice(2, -2);
      if (item.indexOf("/") == -1) {
        item = item.match(regexBackToFront);
      } else {
        item = item.match(regexFrontToBack);
      }
      return item;
    });
  } else {
    return false;
  }
  return tokens;
};
