import React, { Fragment, useEffect, useState } from "react";
import {
  DatePicker,
  Label,
  PrimaryButton,
  TextField,
  Checkbox,
  Stack,
  IStackTokens,
  FontIcon,
  TooltipHost,
  TooltipDelay,
} from "@fluentui/react";
import { Switch, useId } from "@fluentui/react-components";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { formatDate } from "../../common/utils/formatting";
import { i18nDatePicker, LABELS_I18N } from "../../common/i18n/pl_PL";

interface SearchProps {
  onSearch: (search: string, selectedDate?: Date) => void;
  onRegisterSwitch: (currentContext: boolean) => void;
  onDatePickerStateChange: () => void;
  query: string;
  queryDate: Date;
  disabled: boolean;
}
const OrganizationSearch: React.FC<SearchProps> = ({
  onSearch,
  onRegisterSwitch,
  onDatePickerStateChange,
  query,
  queryDate,
  disabled,
}) => {
  const [krs, setKrs] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [krsError, setKrsError] = useState<string>("");
  const [datePickerDisabled, setDatePickerDisabled] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const { isAuthorized } = useAuthorization();
  const tooltipId = useId("registerTooltip");
  const stateTooltipId = useId("stateTooltip");

  const KRS_ERROR_MESSAGE = `${LABELS_I18N.organizationSearch.krsErrorMessage} ${checked ? "NIP" : "KRS"}`;

  useEffect(() => {
    setKrs(query);
    setSelectedDate(queryDate);
  }, [query, queryDate]);

  useEffect(() => {
    if (!isAuthorized) {
      setKrsError("");
      setKrs("");
      setChecked(false);
      setSelectedDate(undefined);
      setDatePickerDisabled(true);
    }
  }, [isAuthorized]);

  useEffect(() => {
    setSelectedDate(undefined);
    setDatePickerDisabled(true);
    setKrs("");
    setKrsError("");
    query = krs;
  }, [checked]);

  const containsOnlyNumbers = (string: string): boolean => {
    return /^\d+$/.test(string);
  };

  const isNipNumber = (nip: string): boolean => {
    var result = false;
    var suma = 0;
    //fixed nip collision
    if (nip.length == 10 && +nip != 0 && nip.charAt(0) != "0") {
      var wagi = new Array(6, 5, 7, 2, 3, 4, 5, 6, 7);
      for (var i = 0; i < 9; i++) {
        suma += +nip.charAt(i) * wagi[i];
      }
      if ((suma % 11) % 10 == +nip.charAt(9)) {
        result = true;
      }
    }
    return result;
  };

  const isValidInput = (): boolean => {
    if (!containsOnlyNumbers(krs)) return false;
    if (checked && containsOnlyNumbers(krs) && krs.length == 10) {
      return isNipNumber(krs);
    }
    if (!checked && containsOnlyNumbers(krs) && krs.length <= 10 && !isNipNumber(krs)) {
      return true;
    }

    return false;
  };

  // @ts-ignore
  const handleKrsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setKrs(newValue || "");
  };

  const handleSearchClick = () => {
    if (isValidInput()) {
      setKrsError("");
      onSearch(krs, selectedDate);
    } else {
      setKrsError(KRS_ERROR_MESSAGE);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  function handleClearCheckbox(_event?: any, checked?: boolean): void {
    onDatePickerStateChange();
    if (checked == false) {
      setDatePickerDisabled(true);
      setSelectedDate(undefined);
    } else {
      setDatePickerDisabled(false);
    }
  }

  const datePickerTokens: IStackTokens = {
    childrenGap: 2,
    maxWidth: "60%",
  };
  const stateTokens: IStackTokens = {
    childrenGap: 2,
    maxWidth: "120px",
  };
  const switchTokens: IStackTokens = {
    childrenGap: 8,
    maxWidth: "120px",
  };

  const handleRegisterSwitch = React.useCallback(
    (ev) => {
      setChecked(ev.currentTarget.checked);
      onRegisterSwitch(ev.currentTarget.checked);
    },
    [setChecked]
  );

  return (
    <div>
      {isAuthorized && (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={switchTokens}>
          <Stack.Item>
            <Label>KRS</Label>
          </Stack.Item>
          <Stack.Item>
            <Switch checked={checked} onChange={handleRegisterSwitch} />
          </Stack.Item>
          <Stack.Item>
            <Label>CEIDG</Label>
          </Stack.Item>
          <Stack.Item>
            <TooltipHost
              content={LABELS_I18N.organizationSearch.toolTip.registryPicker}
              id={tooltipId}
              delay={TooltipDelay.zero}
            >
              <FontIcon iconName="info" aria-describedby={tooltipId}></FontIcon>
            </TooltipHost>
          </Stack.Item>
        </Stack>
      )}
      <Label className="ms-fontSize-18">Wyszukaj podmiot</Label>
      <TextField
        onChange={handleKrsChange}
        onKeyDown={handleKeyDown}
        value={krs}
        placeholder={`${
          checked
            ? LABELS_I18N.organizationSearch.textField.placeholder.part1
            : LABELS_I18N.organizationSearch.textField.placeholder.part2
        }`}
      />
      {isAuthorized && !checked && (
        <Fragment>
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={stateTokens}>
            <Stack.Item>
              <Label>Stan na dzień: </Label>
            </Stack.Item>
            <Stack.Item>
              <TooltipHost
                id={stateTooltipId}
                delay={TooltipDelay.zero}
                content={LABELS_I18N.organizationSearch.toolTip.date}
              >
                <FontIcon iconName="info" aria-describedby={stateTooltipId}></FontIcon>
              </TooltipHost>
            </Stack.Item>
          </Stack>
          <Stack horizontal reversed horizontalAlign="space-between" verticalAlign="center" tokens={datePickerTokens}>
            <Stack.Item grow={8}>
              <DatePicker
                ariaLabel={LABELS_I18N.organizationSearch.datePicker.ariaLabel}
                value={selectedDate}
                onSelectDate={setSelectedDate as (date: Date) => void}
                formatDate={formatDate}
                disabled={datePickerDisabled}
                strings={i18nDatePicker}
                firstDayOfWeek={1}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <Checkbox onChange={handleClearCheckbox} checked={!datePickerDisabled} />
            </Stack.Item>
          </Stack>
        </Fragment>
      )}
      <div className="ms-fontColor-sharedRed20">{krsError}</div>
      <div className="blink-actions">
        <PrimaryButton
          text={LABELS_I18N.organizationSearch.primaryButton.text}
          onClick={handleSearchClick}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default OrganizationSearch;
