import * as React from "react";
import { Fragment } from "react";
import { Label, DefaultButton, Stack, Dialog, DialogFooter, PrimaryButton, Spinner } from "@fluentui/react";
import { useUserAsset } from "../taskpane/userAssets/UserAssetContext";
import CollectionDataGrid from "./CollectionDataGrid";
import NewCollectionComponent from "./NewCollectionComponent";
import { useAuthorization } from "../taskpane/authentication/AuthorizationContext";
import { LABELS_I18N } from "../common/i18n/pl_PL";
import CollectionEditComponent from "./CollectionEditComponent";
import { Collection } from "../taskpane/userAssets/CollectionModel";
import CollectionInviteComponent from "./CollectionInviteComponent";
import { CancelIcon } from "@fluentui/react-icons-mdl2";

const ManagementPage = () => {
  const {
    closeManagementPage,
    collections,
    groups,
    deleteOwnedCollection,
    deleteCollectionSubscription,
    getCollectionForEdit,
    updateCollectionDetails,
    isUpdateLoading,
    error,
    setError,
    inviteCollection,
    setIsUserAssetsManagement,
  } = useUserAsset();
  const { userId, isAuthorized } = useAuthorization();

  const [isConfirmCollectionDelete, setIsConfirmCollectionDelete] = React.useState<boolean>(false);
  const [currentGroupId, setCurrentGroupId] = React.useState<string>("");
  const [currentCollectionId, setCurrentCollectionId] = React.useState<string>("");
  const [currentcollectionUserId, setCurrentcollectionUserId] = React.useState<string>("");
  const [isCollectionEdit, setIsCollectionEdit] = React.useState<boolean>(false);
  const [isCollectionInvite, setIsCollectionInvite] = React.useState<boolean>(false);
  const [currentInviteCollectionId, setCurrentInviteCollectionId] = React.useState<string>("");
  const [currentEditcollectionUserId, setCurrentEditcollectionUserId] = React.useState<string>("");
  const [currentEditCollection, setCurrentEditCollection] = React.useState<Collection>(null);

  React.useEffect(() => {
    if (!isAuthorized) {
      setIsUserAssetsManagement(false);
    }
  }, [isAuthorized]);

  function onCloseClick(): void {
    setIsCollectionEdit(false);
    closeManagementPage();
  }

  async function onDeleteCollectionHandler(groupId: string, collectionId: string, collectionUserId: string) {
    setIsConfirmCollectionDelete(true);
    setCurrentGroupId(groupId);
    setCurrentCollectionId(collectionId);
    setCurrentcollectionUserId(collectionUserId);
  }
  function onDeleteCollectionConfirm(): void {
    if (currentcollectionUserId == userId) {
      deleteOwnedCollection(currentCollectionId);
      setIsConfirmCollectionDelete(false);
      setCurrentGroupId("");
      setCurrentCollectionId("");
      setCurrentcollectionUserId("");
      setIsCollectionEdit(false);
    } else {
      deleteCollectionSubscription(currentGroupId, currentCollectionId);
      setIsConfirmCollectionDelete(false);
      setCurrentGroupId("");
      setCurrentCollectionId("");
      setCurrentcollectionUserId("");
      setIsCollectionEdit(false);
    }
  }
  function onDeleteCollectionReject(): void {
    setIsConfirmCollectionDelete(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onEditCollectionHandler(collectionId: string, currentUserId: string) {
    setCurrentEditCollection(await getCollectionForEdit(collectionId));
    setCurrentEditcollectionUserId(currentUserId);
    setIsCollectionEdit(true);
  }

  function onCancelEditHandler() {
    setIsCollectionEdit(false);
  }

  function onSaveEditHandler(
    editedCollectionId: string,
    editedCollectionName: string,
    editedCollectionLabel: string,
    editedTemplateName?: string,
    editedTemplateText?: string
  ) {
    updateCollectionDetails(
      editedCollectionId,
      editedCollectionName,
      editedCollectionLabel,
      editedTemplateName,
      editedTemplateText
    );
    setIsCollectionEdit(false);
  }

  function onInviteCollectionHandler(collectionId: string) {
    setCurrentInviteCollectionId(collectionId);
    setIsCollectionInvite(true);
  }

  function onInviteCollectionCancelHandler() {
    setCurrentInviteCollectionId("");
    setIsCollectionInvite(false);
  }
  function onInviteSubmitHandler(collectionId: string, email: string[]) {
    inviteCollection(collectionId, email);
    setCurrentInviteCollectionId("");
    setIsCollectionInvite(false);
  }
  const titleLabelStyles = { root: { padding: "0px 0px 30px 0px", fontSize: "large" } };

  return (
    <Fragment>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="start">
        <Stack.Item>
          <Label styles={titleLabelStyles}>{LABELS_I18N.managementPage.title}</Label>
        </Stack.Item>
        <Stack.Item>
          {!isCollectionEdit && !isUpdateLoading && (
            <div className="icon-wrap">
              <CancelIcon onClick={onCloseClick} />
            </div>
          )}
        </Stack.Item>
      </Stack>

      <>
        <CollectionDataGrid
          collectionFeed={collections}
          groupFeed={groups}
          currentUserId={userId}
          onDeleteCollection={onDeleteCollectionHandler}
          onEditCollection={onEditCollectionHandler}
          onInviteCollection={onInviteCollectionHandler}
        />
      </>
      {!isCollectionEdit && !isUpdateLoading && <NewCollectionComponent />}
      {isCollectionEdit && !isUpdateLoading && (
        <CollectionEditComponent
          editCollection={currentEditCollection}
          userId={currentEditcollectionUserId}
          onCancelEdit={onCancelEditHandler}
          onSaveEdit={onSaveEditHandler}
        />
      )}
      {isUpdateLoading && <Spinner style={{ margin: "30px 30px 30px 30px" }} />}
      {isConfirmCollectionDelete && (
        <Dialog hidden={false}>
          <Label className="ms-fontSize-14">
            {currentcollectionUserId == userId
              ? LABELS_I18N.managementPage.deleteMessage.variant
              : LABELS_I18N.managementPage.deleteMessage.default}
          </Label>
          <DialogFooter>
            <PrimaryButton text={LABELS_I18N.general.delete} onClick={() => onDeleteCollectionConfirm()} />
            <DefaultButton text={LABELS_I18N.general.cancel} onClick={() => onDeleteCollectionReject()} />
          </DialogFooter>
        </Dialog>
      )}
      {isCollectionInvite && (
        <Dialog hidden={false}>
          <CollectionInviteComponent
            inviteCollectionId={currentInviteCollectionId}
            onCancel={onInviteCollectionCancelHandler}
            onSubmit={onInviteSubmitHandler}
          />
        </Dialog>
      )}
      {error && (
        <Dialog hidden={false}>
          <Label className="ms-fontSize-14">{error.message}</Label>
          <DialogFooter>
            <PrimaryButton text="OK" onClick={() => setError(null)} />
          </DialogFooter>
        </Dialog>
      )}
    </Fragment>
  );
};

export default ManagementPage;
