export const formatDate = (date: Date): string => {
  const month = date.getMonth() + 1; // + 1 because 0 indicates the first Month of the Year.
  const day = date.getDate();
  const paddedDay = "0" + day;
  const returnedDay = paddedDay.length == 3 ? day : paddedDay;
  const year = date.getFullYear();
  const paddedMonth = "0" + month;
  const returnedMonth = paddedMonth.length == 3 ? month : paddedMonth;
  return `${returnedDay}-${returnedMonth}-${year}`;
};
