import * as React from "react";
import { Fragment } from "react";
import OrganizationPage from "../organization/OrganizationPage";
import { useUserAsset } from "../userAssets/UserAssetContext";
import Mgmt from "../../management/CollectionManagementComponent";

const AppWrapper = () => {
  const { isUserAssetsManagement } = useUserAsset();
  return <Fragment>{!isUserAssetsManagement ? <OrganizationPage /> : <Mgmt />}</Fragment>;
};

export default AppWrapper;
