import * as React from "react";
import { useState } from "react";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { TextField, Stack, IStackTokens, DefaultButton, Dialog, DialogFooter, Label } from "@fluentui/react";
import { LABELS_I18N } from "../common/i18n/pl_PL";
import { useUserAsset } from "../taskpane/userAssets/UserAssetContext";

const MAX_STRING_LENGTH = 100;

const stackTokens: IStackTokens = { childrenGap: 20, padding: "20px 0px 50px 0px" };

const NewCollectionComponent = () => {
  const { createCollection } = useUserAsset();

  const [collectionName, setCollectionName] = useState("");
  const [collectionNameError, setCollectionNameError] = useState("");
  const [collectionLabel, setCollectionLabel] = useState("");
  const [collectionLabelError, setCollectionLabelError] = useState("");
  const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false);

  const validateCollectionName = (value: string) => {
    setCollectionNameError("");
    if (!value) {
      setCollectionNameError("Nazwa kolekcji jest wymagana");
      return value;
    }
    if (value.length > MAX_STRING_LENGTH) {
      setCollectionNameError(`Maksymalna długość to ${MAX_STRING_LENGTH}`);
    }
    return value.substring(0, MAX_STRING_LENGTH);
  };
  const validateCollectionLabel = (value: string) => {
    setCollectionLabelError("");
    if (value.length > MAX_STRING_LENGTH) {
      setCollectionLabelError(`Maksymalna długość to ${MAX_STRING_LENGTH}`);
    }
    return value.substring(0, MAX_STRING_LENGTH);
  };

  const handleSubmit = (collectionName: string, collectionLabel: string) => {
    if (collectionName) {
      createCollection(collectionName, collectionLabel);
      setCollectionLabel("");
      setCollectionName("");
      setCollectionLabelError("");
      setCollectionNameError("");
    } else {
      setIsShowErrorModal(true);
    }
  };

  let saveButtonLableStyles = { root: { padding: "0px 0px 0px 5px" } };

  return (
    <React.Fragment>
      <Stack
        horizontal
        tokens={stackTokens}
        verticalAlign="end"
        horizontalAlign="start"
        style={{ padding: "10px 10px 10px 10px" }}
      >
        <Stack.Item>
          <TextField
            label={LABELS_I18N.managementPage.newCollectionForm.collectionNameLabel}
            value={collectionName}
            onChange={(_e, newValue) => setCollectionName(validateCollectionName(newValue || ""))}
            required
          />
          <div className="ms-fontColor-sharedRed20">{collectionNameError}</div>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label={LABELS_I18N.managementPage.newCollectionForm.collectionLabelLabel}
            value={collectionLabel}
            onChange={(_e, newValue) => setCollectionLabel(validateCollectionLabel(newValue || ""))}
          />
          <div className="ms-fontColor-sharedRed20">{collectionLabelError}</div>
        </Stack.Item>
        <DefaultButton onClick={() => handleSubmit(collectionName, collectionLabel)}>
          {<SaveIcon />}
          <Label styles={saveButtonLableStyles}>{LABELS_I18N.general.create}</Label>
        </DefaultButton>
      </Stack>
      {isShowErrorModal && (
        <Dialog hidden={false}>
          <Label className="ms-fontSize-14">
            {LABELS_I18N.managementPage.newCollectionForm.collectionNameIsRequiredErrorMessage}
          </Label>
          <DialogFooter>
            <DefaultButton text="Ok" onClick={() => setIsShowErrorModal(false)} />
          </DialogFooter>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default NewCollectionComponent;
