export const LEGAL_FORM_DROP_OPT = new Map();
LEGAL_FORM_DROP_OPT.set("sp_j", { priority: 7, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("sp_zoo", { priority: 1, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("sp_k", { priority: 4, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("sp_p", { priority: 8, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("sp_ka", { priority: 5, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("sp_pakc", { priority: 6, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("sp_akc", { priority: 2, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("ezig", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("sp_eu", { priority: 9, group: "partnership" });
LEGAL_FORM_DROP_OPT.set("spol", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("spol_eu", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("prze_pa", { priority: 1, group: "enterprise" });
LEGAL_FORM_DROP_OPT.set("fund", { priority: 1, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("stow", { priority: 2, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("zw", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("zw_ogr", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("stow_ogr", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("inol_Łu", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("inol_lu", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("tow_wz", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("tow_re", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("opz_rp", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("goz_zu", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("goz_zr", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("igb", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("spzoz", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("kr", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("rzb", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zrzb", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zrkor", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zzri", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("cerz", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("izrz", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zrp", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zhu", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zrt", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("orzhu", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("orzt", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("iopg", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("izgo", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("zwza", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("jozzpop", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("ozwm", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("ozrm", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("zwpr", { priority: 1, group: "cooperative" });
LEGAL_FORM_DROP_OPT.set("fkzp", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("zwsp", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("pzwsp", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("iosz", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("nopopp", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("iopopp", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("kopopp", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("nopkopp", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("skf", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("inne", { priority: 1, group: "other" });
LEGAL_FORM_DROP_OPT.set("jtspop", { priority: 3, group: "associacion" });
LEGAL_FORM_DROP_OPT.set("jdg", { priority: 1, group: "jdg" });
