import * as React from "react";
import { Fragment } from "react";
import { BuildDefinitionIcon, ClockIcon, BullseyeIcon, DesignIcon } from "@fluentui/react-icons-mdl2";

const OrganizationTutorial = () => (
  <Fragment>
    <div className="blink-tutorial-main">
      <div className="blink-tutorial-item">
        <div className="blink-tutorial-title">
          <div className="blink-tutorial-h1">
            <BuildDefinitionIcon />
            <div>Automatyzacja</div>
          </div>
        </div>
        <div className="blink-tutorial-text">
          Informacje o podmiotach wpisanych do KRS oraz osób fizycznych wpisanych do CEIDG dostępne w mgnieniu oka.
        </div>
      </div>
      <div className="blink-tutorial-item">
        <div className="blink-tutorial-title">
          <div className="blink-tutorial-h1">
            <ClockIcon />
            <div>Oszczędność czasu</div>
          </div>
        </div>
        <div className="blink-tutorial-text">
          Oszczędność co najmniej kilku godzin pracy za cenę niższą niż minimalna stawka godzinowa.
        </div>
      </div>
      <div className="blink-tutorial-item">
        <div className="blink-tutorial-title">
          <div className="blink-tutorial-h1">
            <BullseyeIcon />
            <div>Jakość</div>
          </div>
        </div>
        <div className="blink-tutorial-text">
          Dane pozyskane za pomocą <b>BlinKRS</b> pochodzą bezpośrednio z rejestrów KRS oraz CEIDG.
        </div>
      </div>
      <div className="blink-tutorial-item">
        <div className="blink-tutorial-title">
          <div className="blink-tutorial-h1">
            <DesignIcon />
            <div>Łatwość użycia</div>
          </div>
        </div>
        <div className="blink-tutorial-text">
          <b>BlinKRS</b> jest prostym w obsłudze dodatkiem do Microsoft Office Word. Korzystaj na dowolnym komputerze w
          firmie.
        </div>
      </div>
    </div>
  </Fragment>
);

export default OrganizationTutorial;
