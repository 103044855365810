import {
  DefaultButton,
  Icon,
  IconButton,
  INavLink,
  IStackTokens,
  Label,
  mergeStyles,
  Nav,
  Panel,
  Stack,
} from "@fluentui/react";
import React, { Fragment } from "react";
import { useState } from "react";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { useUserAsset } from "../userAssets/UserAssetContext";
import { LABELS_I18N } from "../../common/i18n/pl_PL";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global document, Office, module, require */

const navigationLinks: INavLink[] = [
  { key: "mgmt", name: LABELS_I18N.headerComponent.sideMenu.collectionManagementLabel, url: "#" },
  { key: "about", name: LABELS_I18N.headerComponent.sideMenu.aboutLabel, url: "https://blinkrs.pl" },
  { key: "contact", name: LABELS_I18N.headerComponent.sideMenu.contactLabel, url: "mailto:kontakt@blinkrs.pl" },
];
let loginDialog: Office.Dialog;
const BlinkHeader = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { isAuthorized, logout, login, setError, fullName } = useAuthorization();
  const { openManagementPage } = useUserAsset();

  const onPanelOpen = () => {
    setIsPanelOpen(true);
  };

  const onPanelDismiss = () => {
    setIsPanelOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  const dialogLoginUrl: string = "https://blinkrs.justblink.com.pl/login.html";
  //const dialogLoginUrl: string = "https://localhost:3000/login.html";
  const handleLogin = async () => {
    Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 70, width: 50 }, (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        setError({
          error: `${result.error.code}`,
          error_description: `${result.error.message}`,
        });
      } else {
        loginDialog = result.value;
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
        loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
      }
    });
    const processLoginMessage = (arg: { message: string; origin: string }) => {
      let messageFromDialog = JSON.parse(arg.message);
      if (messageFromDialog.status === "success") {
        // We now have a valid access token.
        loginDialog.close();
        login(messageFromDialog.result);
      } else {
        // Something went wrong with authentication or the authorization of the web application.
        loginDialog.close();
        setError(messageFromDialog.result);
      }
    };

    const processLoginDialogEvent = (arg) => {
      switch (arg.error) {
        case 12002:
          setError({
            error_description:
              "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.",
            error: "12002",
          });
          break;
        case 12003:
          setError({
            error_description: "The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.",
            error: "12003",
          });
          break;
        case 12006:
          // 12006 means that the user closed the dialog instead of waiting for it to close.
          // It is not known if the user completed the login or logout, so assume the user is
          // logged out and revert to the app's starting state. It does no harm for a user to
          // press the login button again even if the user is logged in.
          logout();
          break;
        default:
          setError({ error_description: "Unknown error in dialog box.", error: arg.error });
          break;
      }
    };
  };
  const handleMgmtPage = async () => {
    openManagementPage();
  };

  function _onLinkClicked(_ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    if (item && item.key == "mgmt" && !isAuthorized) {
      handleLogin();
      setIsPanelOpen(false);
    }
    if (item && item.key == "mgmt" && isAuthorized) {
      handleMgmtPage();
      setIsPanelOpen(false);
    }
  }

  const authorizedTokens: IStackTokens = {
    childrenGap: 8,
    maxWidth: "50%",
    padding: "5px",
  };

  const iconClass = mergeStyles({
    fontSize: 25,
    height: 25,
    width: 25,
  });

  return (
    <header className="ms-NavBar blink_header ms-depth-16">
      <div className="ms-NavBar-item header-main">
        {isAuthorized ? (
          <Fragment>
            <Stack horizontal horizontalAlign="start" verticalAlign="center" tokens={authorizedTokens}>
              <Stack.Item>
                <DefaultButton text={LABELS_I18N.headerComponent.signOutButtonLabel} onClick={handleLogout} />
              </Stack.Item>
              <Stack.Item>
                <Icon iconName="signin" className={iconClass} />
              </Stack.Item>
              <Stack.Item>
                <Label>{fullName}</Label>
              </Stack.Item>
            </Stack>
          </Fragment>
        ) : (
          <Fragment>
            <DefaultButton text={LABELS_I18N.headerComponent.signInButtonLabel} onClick={handleLogin} />
          </Fragment>
        )}
      </div>
      <div className="ms-NavBar-item ms-NavBar-item--right">
        <IconButton
          iconProps={{ iconName: "GlobalNavButton" }}
          title={LABELS_I18N.headerComponent.sideMenu.title}
          onClick={onPanelOpen}
        />
        <Panel headerText={LABELS_I18N.headerComponent.sideMenu.title} isOpen={isPanelOpen} onDismiss={onPanelDismiss}>
          <Nav
            onLinkClick={_onLinkClicked}
            groups={[
              {
                links: navigationLinks,
              },
            ]}
          />
        </Panel>
      </div>
    </header>
  );
};

export default BlinkHeader;
