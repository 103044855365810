import * as React from "react";
import { EditRegular, DeleteRegular, PeopleAddRegular } from "@fluentui/react-icons";
import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Button,
  TableColumnId,
  DataGridCellFocusMode,
} from "@fluentui/react-components";
import { Collections } from "../taskpane/userAssets/CollectionModel";
import { Group, Groups } from "../taskpane/userAssets/GroupModel";
import { LABELS_I18N } from "../common/i18n/pl_PL";
import { Label } from "@fluentui/react";
import { CancelIcon } from "@fluentui/react-icons-mdl2";

interface DataGridProps {
  collectionFeed?: Collections;
  groupFeed?: Groups;
  currentUserId?: string;
  onDeleteCollection?: (groupId: string, collectionId: string, userId: string) => void;
  onEditCollection?: (collectionId: string, userId: string) => void;
  onInviteCollection?: (collectionId: string) => void;
}

type Item = {
  collectionName: string;
  collectionLabel: string;
  collectionId: string;
  collectionGroupId: string;
  collectionOwnerId: string;
  currentUserId: string;
  memberCount: string;
  dataGridProperties: DataGridProps;
};

function isEditButtonRendered(collectionOwnerId: string, currentUserId: string): boolean {
  return collectionOwnerId == currentUserId;
}

function isDeleteButtonRendered(collectionOwnerId: string, currentUserId: string): boolean {
  return !collectionOwnerId.includes("JustBlink") && collectionOwnerId != currentUserId;
}

function isCancellButtonRendered(collectionOwnerId: string, currentUserId: string): boolean {
  return collectionOwnerId == currentUserId;
}

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "collectionName",
    compare: (a, b) => {
      return a.collectionName.localeCompare(b.collectionName);
    },
    renderHeaderCell: () => {
      return LABELS_I18N.managementPage.dataGrid.collectionNameHeaderCell;
    },
    renderCell: (item) => {
      return <TableCellLayout>{item.collectionName}</TableCellLayout>;
    },
  }),
  createTableColumn<Item>({
    columnId: "label",
    compare: (a, b) => {
      return a.collectionLabel.localeCompare(b.collectionLabel);
    },
    renderHeaderCell: () => {
      return LABELS_I18N.managementPage.dataGrid.collectionLabelHeaderCell;
    },
    renderCell: (item) => {
      return <TableCellLayout>{item.collectionLabel}</TableCellLayout>;
    },
  }),
  createTableColumn<Item>({
    columnId: "memberCount",
    compare: (a, b) => {
      return a.memberCount.localeCompare(b.memberCount);
    },
    renderHeaderCell: () => {
      return LABELS_I18N.managementPage.dataGrid.collectionMemberCountHeaderCell;
    },
    renderCell: (item) => {
      return (
        <>
          {isEditButtonRendered(item.collectionOwnerId, item.currentUserId) && (
            <TableCellLayout>{item.memberCount}</TableCellLayout>
          )}
        </>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "actions",
    renderHeaderCell: () => {
      return LABELS_I18N.managementPage.dataGrid.collectionActionsHeaderCell;
    },
    renderCell: (a) => {
      return (
        <>
          {isEditButtonRendered(a.collectionOwnerId, a.currentUserId) && (
            <Button
              aria-label="Edit"
              icon={<EditRegular />}
              onClick={() => a.dataGridProperties.onEditCollection(a.collectionId, a.currentUserId)}
            />
          )}
          {isEditButtonRendered(a.collectionOwnerId, a.currentUserId) && (
            <Button
              aria-label="Invite"
              icon={<PeopleAddRegular />}
              onClick={() => a.dataGridProperties.onInviteCollection(a.collectionId)}
            />
          )}
          {isDeleteButtonRendered(a.collectionOwnerId, a.currentUserId) && (
            <Button
              aria-label="Delete collection subscription"
              icon={<DeleteRegular />}
              onClick={() =>
                a.dataGridProperties.onDeleteCollection(a.collectionGroupId, a.collectionId, a.collectionOwnerId)
              }
            />
          )}
          {isCancellButtonRendered(a.collectionOwnerId, a.currentUserId) && (
            <Button
              aria-label="Remove collection"
              icon={<CancelIcon />}
              onClick={() =>
                a.dataGridProperties.onDeleteCollection(a.collectionGroupId, a.collectionId, a.collectionOwnerId)
              }
            />
          )}
        </>
      );
    },
  }),
];

const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
  switch (columnId) {
    case "singleAction":
      return "none";
    case "actions":
      return "group";
    default:
      return "cell";
  }
};

function getGroupIdByCollectionId(collectionId: string, group: Groups) {
  if (collectionId == "default") return "";
  let candidate: Group =
    group &&
    group
      .filter((item) => item.ownCollections && item.ownCollections.findIndex((col) => col == collectionId) != -1)
      .shift();
  if (typeof candidate === "undefined") {
    candidate =
      group &&
      group
        .filter((item) => item.members.filter((mem) => mem.collections.findIndex((col) => col == collectionId) != -1))
        .shift();
  }
  return candidate && candidate?.id;
}
//let dataGridRowStyle = { borderBottomStyle: "ridge" };
let dataGridHeaderStyle = { fontWeight: "bold" };

const CollectionDataGrid: React.FC<DataGridProps> = ({
  collectionFeed,
  currentUserId,
  groupFeed,
  onDeleteCollection,
  onEditCollection,
  onInviteCollection,
}) => {
  try {
    let items: Item[] = collectionFeed.flatMap((feedItem) => {
      let currentGroupId: string = getGroupIdByCollectionId(feedItem.id, groupFeed);
      let currentGroup: Group = groupFeed && groupFeed.filter((item) => item.id == currentGroupId).shift();

      return {
        collectionGroupId: currentGroupId,
        collectionId: feedItem.id,
        collectionLabel: feedItem.metadata.uiprops.label,
        collectionOwnerId: feedItem.metadata.ownerId,
        collectionName: feedItem.metadata.collectionName,
        currentUserId: currentUserId,
        memberCount:
          currentGroup &&
          currentGroup.members
            .filter((item) => item.collections.findIndex((col) => col == feedItem.id) != -1)
            .length.toString(),
        dataGridProperties: { onDeleteCollection, onEditCollection, onInviteCollection },
      };
    });
    return (
      <DataGrid items={items} columns={columns} sortable getRowId={(item: Item) => item.collectionId}>
        <DataGridHeader>
          <DataGridRow style={{ borderBottomStyle: "ridge" }}>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell style={dataGridHeaderStyle}>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item> key={rowId} style={{ borderBottomStyle: "ridge" }}>
              {({ renderCell, columnId }) => (
                <DataGridCell focusMode={getCellFocusMode(columnId)}>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    );
  } catch (error) {
    return (
      <React.Fragment>
        <Label>{LABELS_I18N.general.ups}</Label>
      </React.Fragment>
    );
  }
};

export default CollectionDataGrid;
