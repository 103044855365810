import * as React from "react";
import BlinkHeader from "./BlinkHeader";
import AppWrapper from "./AppWrapper";
import { AuthorizationProvider } from "../authentication/AuthorizationContext";
import { UserAssetProvider } from "../userAssets/UserAssetContext";
import { QueryClient, QueryClientProvider } from "react-query";
import AxiosConfig from "../api/AxiosConfig";

///* global Word, require */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

const App: React.FC<AppProps> = () => {
  const queryClient = new QueryClient();

  return (
    <AuthorizationProvider>
      <AxiosConfig>
        <QueryClientProvider client={queryClient}>
          <UserAssetProvider>
            <BlinkHeader />
            <section className="blink-wrapper ms-bgColor-neutralLighterAlt">
              <AppWrapper />
            </section>
          </UserAssetProvider>
        </QueryClientProvider>
      </AxiosConfig>
    </AuthorizationProvider>
  );
};

export default App;
