import { CollectionInstanceRequest } from "../userAssets/CollectionModel";

const CollectionAPI = {
  baseURL: "https://api.justblink.com.pl",
  //baseURL: "http://localhost:3001",

  getCollectionDetails: (collectionId: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections/${collectionId}`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  },
  searchCollection: (collectionList: string[]): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections/search`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        collectionId: collectionList,
      }),
    });
  },
  updateCollection: (collectionId: string, collectionName: string, collectionLabel: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections/${collectionId}`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify({
        collectionName: collectionName,
        collectionLabel: collectionLabel,
      }),
    });
  },
  updateCollectionTemplate: (
    collectionId: string,
    collectionTemplateName: string,
    collectionTemplateText: string
  ): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections/${collectionId}/templates/${collectionTemplateName}`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        text: collectionTemplateText,
      }),
    });
  },
  createCollection: (collectionName: string, collectionLabel?: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    let requestBody = {
      collectionName: collectionName,
      collectionLabel: collectionLabel,
    };
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(requestBody),
    });
  },
  deleteCollection: (collectionId: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections/${collectionId}`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  },
  createCollectionTemplateInstance: (
    data: CollectionInstanceRequest,
    collectionId: string,
    collectionTemplateName: string
  ): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${CollectionAPI.baseURL}/collections/${collectionId}/templates/${collectionTemplateName}/messages`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });
  },
};

export default CollectionAPI;
