import React from "react";
import { Text } from "@fluentui/react-components";

const CollectionEditManualComponent = () => {
  return (
    <>
      <h3>Instrukcja edycji wzoru opisu strony (komparycji)</h3>

      <p>
        Poniżej znajduje się tabela prezentująca dostępne do edycji tagi. Nazwa tagu musi być zgodna z tą podaną w
        tabeli.
      </p>

      <table style={{ width: "90%%" }}>
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Nazwa tagu</th>
            <th style={{ width: "65%" }}>Opis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "35%" }}>nazwa</td>
            <td style={{ width: "65%" }}>nazwa podmiotu z rejestru KRS lub CEIDG</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>NIP</td>
            <td style={{ width: "65%" }}>NIP podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>KRS</td>
            <td style={{ width: "65%" }}>KRS podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>REGON</td>
            <td style={{ width: "65%" }}>REGON podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>miejscowość</td>
            <td style={{ width: "65%" }}>miejscowość z adresu pocztowego podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>siedziba</td>
            <td style={{ width: "65%" }}>miasto siedziby podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>kraj</td>
            <td style={{ width: "65%" }}>kraj z adresu pocztowego podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>ulica</td>
            <td style={{ width: "65%" }}>ulica z adresu pocztowego podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>numer</td>
            <td style={{ width: "65%" }}>numer ulicy z adresu pocztowego podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>lokal</td>
            <td style={{ width: "65%" }}>lokal z adresu pocztowego podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>kod pocztowy</td>
            <td style={{ width: "65%" }}>kod pocztowy z adresu pocztowego podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>sąd rejestrowy</td>
            <td style={{ width: "65%" }}>sąd rejestrowy właściwy dla siedziby podmiotu</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>kapitał</td>
            <td style={{ width: "65%" }}>kapitał sp&oacute;łek kapitałowych</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>waluta</td>
            <td style={{ width: "65%" }}>waluta kapitału sp&oacute;łek kapitałowych</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>kapitał opłacony</td>
            <td style={{ width: "65%" }}>kapitał sp&oacute;łek kapitałowych</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>waluta opłacona</td>
            <td style={{ width: "65%" }}>waluta kapitału sp&oacute;łek kapitałowych</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>czy przedsiębiorstwo</td>
            <td style={{ width: "65%" }}>
              w przypadku podmiot&oacute;w z rejestru stowarzyszeń KRS informacja, czy stowarzyszenie działa także jako
              przedsiębiorstwo
            </td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>imię</td>
            <td style={{ width: "65%" }}>imię prowadzącego jednoosobową działalność gospodarczą (tylko CEIDG)</td>
          </tr>
          <tr>
            <td style={{ width: "35%" }}>nazwisko</td>
            <td style={{ width: "65%" }}>nazwisko prowadzącego jednoosobową działalność gospodarczą (tylko CEIDG)</td>
          </tr>
        </tbody>
      </table>

      <p>
        Aby tagi zostały podmienione i poprawna komparycja została przygotowana, tagi muszą być wstawione w polu edycji
        w podw&oacute;jnych nawiasach kwadratowych.
      </p>

      <p>Przykłady poprawnych tag&oacute;w:</p>

      <p>
        <Text weight="bold" className="manual-text-code">
          Firma pod sp&oacute;łką [[nazwa]]
        </Text>{" "}
        <br />
        zostanie zastąpiony nazwą podmiotu. Na przykład <br />
        <Text weight="bold" className="manual-text-code">
          Firma pod sp&oacute;łką JUST BLINK ROGALA, JACHEMEK SP&Oacute;ŁKA JAWNA
        </Text>
      </p>

      <p>
        <Text weight="bold" className="manual-text-code">
          adres: [[ulica]] [[numer]][[++ lok. ++lokal]]
        </Text>{" "}
        <br />
        zostanie zastąpione numerem lokalu z prefiksem jak tekst między &quot;++&quot;. Na przykład <br />
        <Text weight="bold" className="manual-text-code">
          adres: ul. Puławska 20 lok.257
        </Text>
      </p>

      <p>
        <Text weight="bold" className="manual-text-code">
          adres: [[ulica/miejscowość]] [[numer]] [[kod pocztowy]]
        </Text>{" "}
        <br />
        zostanie zastąpione nazwą ulicy lub nazwą miejscowości jeżeli w adresie nie ma nazwy ulicy. Na przykład <br />
        <Text weight="bold" className="manual-text-code">
          adres: Lauda 1 11-100
        </Text>
      </p>

      <p>
        <Text weight="bold" className="manual-text-code">
          REGON: [[REGON/(w rejestrze nie ujawniono REGON)]]
        </Text>{" "}
        <br />
        zostanie zastąpiony numerem REGON lub tekstem występującym po znaku &quot;/&quot;. Na przykład <br />
        <Text weight="bold" className="manual-text-code">
          REGON: (w rejestrze nie ujawniono REGON)
        </Text>
      </p>

      <p>
        Jeżeli tekst alternatywny będzie identyczny jak nazwa tagu, to powyższy efekt można osiągnąć korzystając z
        zapisu:
      </p>

      <p>
        <Text weight="bold" className="manual-text-code">
          REGON: [[!!(w rejestrze nie ujawniono REGON)!!REGON]]
        </Text>{" "}
        <br />
        zostanie zastąpiony numerem REGON lub tekstem występującym między &quot;!!&quot;. Na przykład <br />
        <Text weight="bold" className="manual-text-code">
          REGON: (w rejestrze nie ujawniono REGON)
        </Text>
      </p>
    </>
  );
};

export default CollectionEditManualComponent;
