import { useEffect } from "react";
import axios, { InternalAxiosRequestConfig } from "axios";
import { useAuthorization } from "../authentication/AuthorizationContext";

export const API = axios.create({
  baseURL: "https://api.justblink.com.pl",
  //baseURL: "http://localhost:3002",
});
// @ts-ignore
const AxiosConfig = ({ children }) => {
  const { isTokenValid, isRefreshTokenValid, refresh, logout } = useAuthorization();

  useEffect(() => {
    API.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        if (config.headers) {
          if (!isTokenValid()) {
            if (!isRefreshTokenValid()) {
              logout();
            } else {
              await refresh();
            }
          }

          // eslint-disable-next-line no-undef
          const token = localStorage.getItem("token");

          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }

          config.headers.Accept = "application/json; charset=utf-8";
        }

        return config;
      },
      // @ts-ignore
      (error) => Promise.reject(error)
    );
  }, []);

  return children;
};

export default AxiosConfig;
