import React from "react";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  DefaultButton,
  IDropdownOption,
  Stack,
  IStackTokens,
  DropdownMenuItemType,
  Dialog,
  DialogFooter,
} from "@fluentui/react";
import { Collection } from "../taskpane/userAssets/CollectionModel";
import { LABELS_I18N, LEGAL_FORM_MAP } from "../common/i18n/pl_PL";
import { LEGAL_FORM_DROP_OPT } from "../common/dict";
import { getUsedTags, validateTagName, TemplateTagValidity } from "../common/utils/templateValidation";
import { HelpIcon } from "@fluentui/react-icons-mdl2";
import CollectionEditManualComponent from "./CollectionEditManualComponent";
interface CollectionEditComponentProps {
  editCollection: Collection;
  userId: String;
  onCancelEdit?: () => void;
  onSaveEdit?: (
    editedCollectionId: string,
    editedCollectionName: string,
    editedCollectionLabel: string,
    editedTemplateName?: string | boolean,
    editedCollectionText?: string | boolean
  ) => void;
}

const stackTokens: IStackTokens = { childrenGap: 20 };

const CollectionEditComponent: React.FC<CollectionEditComponentProps> = ({
  editCollection,
  onCancelEdit,
  onSaveEdit,
}) => {
  const [currentTemplateName, setCurrentTemplateName] = React.useState<string>("");
  const [currentTemplateText, setCurrentTemplateText] = React.useState<string>("");
  const [templateTagValidity, setTemplateTagValidity] = React.useState<TemplateTagValidity>({ isValid: true });
  const [currentCollectionName, setCurrentCollectionName] = React.useState<string>("");
  const [currentCollectionLabel, setCurrentCollectionLabel] = React.useState<string>("");
  const [isShowManualDialog, setIsShowManualDialog] = React.useState<boolean>(false);

  const options: IDropdownOption[] = setDropDownOptions(editCollection);

  function setDropDownOptions(collections: Collection): IDropdownOption[] {
    let optDivider = {
      key: "divider",
      text: "divider",
      itemType: DropdownMenuItemType.Divider,
    };
    let optPartnershipHeader = {
      key: "prtnrHeader",
      text: LABELS_I18N.collectionEditComponent.dropdown.groupPartnership,
      itemType: DropdownMenuItemType.Header,
    };
    let optAssociacionHeader = {
      key: "assocHeader",
      text: LABELS_I18N.collectionEditComponent.dropdown.groupAssociacion,
      itemType: DropdownMenuItemType.Header,
    };
    let optCooperativeHeader = {
      key: "assocHeader",
      text: LABELS_I18N.collectionEditComponent.dropdown.groupCooperative,
      itemType: DropdownMenuItemType.Header,
    };
    let optOtherHeader = {
      key: "otherHeader",
      text: LABELS_I18N.collectionEditComponent.dropdown.groupOther,
      itemType: DropdownMenuItemType.Header,
    };
    let opt = collections.templates.flatMap((tmpl) => {
      return {
        key: tmpl.name,
        text: LEGAL_FORM_MAP.get(tmpl.name),
        group: LEGAL_FORM_DROP_OPT.get(tmpl.name).group,
        prioriry: LEGAL_FORM_DROP_OPT.get(tmpl.name).priority,
      };
    });
    let sortOpt = opt.sort((a, b) => a.prioriry - b.prioriry);
    let optPartnership = sortOpt.filter((item) => item.group == "partnership");
    let optAssociacion = sortOpt.filter((item) => item.group == "associacion");
    let optCooperative = sortOpt.filter((item) => item.group == "cooperative");
    let optOther = sortOpt.filter(
      (item) => !(item.group == "partnership" || item.group == "associacion" || item.group == "cooperative")
    );
    return [
      optDivider,
      optPartnershipHeader,
      optDivider,
      ...optPartnership,
      optAssociacionHeader,
      optDivider,
      ...optAssociacion,
      optCooperativeHeader,
      optDivider,
      ...optCooperative,
      optOtherHeader,
      optDivider,
      ...optOther,
    ];
  }

  React.useEffect(() => {
    setCurrentCollectionName(editCollection.metadata.collectionName);
    setCurrentCollectionLabel(editCollection.metadata.uiprops.label);
  }, []);

  React.useEffect(() => {
    if (currentTemplateName.length > 0) {
      setCurrentTemplateText(
        editCollection.templates?.filter((item) => item.name == currentTemplateName).shift()?.text.base || ""
      );
    }
  }, [currentTemplateName]);

  const handleDropDownChange = (_e, selection?: IDropdownOption<string>) => {
    setCurrentTemplateName(selection.key.toString() || "");
  };

  const handleCollectionNameChange = (_e, newValue?: string) => {
    setCurrentCollectionName(newValue || "");
  };

  const handleCollectionLabelChange = (_e, newValue?: string) => {
    setCurrentCollectionLabel(newValue || "");
  };

  const handleCollectionTemplateTextChange = (_e, newValue?: string) => {
    setCurrentTemplateText(newValue || "");
    let isTemplateValid: TemplateTagValidity = validateTagName(getUsedTags(newValue));
    setTemplateTagValidity(isTemplateValid);
  };

  return (
    <div style={{ padding: "10px 10px 10px 10px", margin: "10px 10px 10px 10px" }}>
      <TextField
        label={LABELS_I18N.collectionEditComponent.collectionNameLabel}
        onChange={handleCollectionNameChange}
        value={currentCollectionName}
      />
      <TextField
        label={LABELS_I18N.collectionEditComponent.collectionLabelLabel}
        onChange={handleCollectionLabelChange}
        value={currentCollectionLabel}
      />
      <Dropdown
        label={LABELS_I18N.collectionEditComponent.collectionTemplateNameDropdownLabel}
        options={options}
        onChange={handleDropDownChange}
      />
      <TextField
        label={`${LABELS_I18N.collectionEditComponent.collectionTemplateTextLabel} ${
          LEGAL_FORM_MAP.get(currentTemplateName) || ""
        }`}
        multiline
        rows={10}
        value={currentTemplateText}
        onChange={handleCollectionTemplateTextChange}
        disabled={currentTemplateText.length == 0 && currentTemplateName.length == 0}
        errorMessage={
          templateTagValidity.isValid
            ? ""
            : `${LABELS_I18N.collectionEditComponent.collectionTemplateUnknownTag}: ${templateTagValidity.tagName}`
        }
      />
      <div className="icon-wrap">
        <HelpIcon onClick={() => setIsShowManualDialog(true)} />
      </div>
      <Stack
        horizontal
        tokens={stackTokens}
        verticalAlign="end"
        horizontalAlign="end"
        style={{ padding: "10px 10px 10px 10px", margin: "10px 10px 10px 10px" }}
      >
        <Stack.Item>
          <PrimaryButton
            text={LABELS_I18N.general.save}
            onClick={() =>
              onSaveEdit(
                editCollection.id,
                currentCollectionName,
                currentCollectionLabel,
                currentTemplateName.length > 0 && currentTemplateName,
                currentTemplateText.length > 0 && currentTemplateText
              )
            }
            disabled={!templateTagValidity.isValid}
          />
        </Stack.Item>
        <Stack.Item>
          <DefaultButton text={LABELS_I18N.general.cancel} onClick={() => onCancelEdit()} />
        </Stack.Item>
      </Stack>
      {isShowManualDialog && (
        <Dialog hidden={false} maxWidth={"70%"}>
          <CollectionEditManualComponent />
          <DialogFooter>
            <PrimaryButton text="OK" onClick={() => setIsShowManualDialog(false)} />
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
};

export default CollectionEditComponent;
