import * as React from "react";

import OrganizationSearch from "./OrganizationSearch";
import OrganizationSearchGus from "./OrganizationSearchGus";
import { useQuery } from "react-query";
import OrganizationsAPI from "../api/OrganizationsAPI";
import { Fragment, useEffect, useState } from "react";
import { AuthError, Organization } from "./OrganizationModel";
import { CollectionInstanceRequest, Collections } from "../userAssets/CollectionModel";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
} from "@fluentui/react";
import OrganizationError from "./OrganizationError";
import { AxiosError } from "axios";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { useUserAsset } from "../userAssets/UserAssetContext";
import OrganizationTutorial from "./OrganizationTutorial";
import CollectionAPI from "../api/CollectionAPI";
import { LABELS_I18N } from "../../common/i18n/pl_PL";
import FeatureStatusAPI from "../api/FeatureStatusAPI";

interface DropdownOptionItem {
  key: string;
  text: string;
}

interface DropdownOptionItems extends Array<DropdownOptionItem> {}

const OrganizationPage = () => {
  const [krs, setKrs] = useState<string>("");
  const [gusStatus, setGusStatus] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [krsFetched, setKrsFetched] = useState<boolean>(false);
  const [queryError, setQueryError] = useState<AxiosError | null>(null);

  const { isAuthorized, isRefreshTokenValid } = useAuthorization();
  const { clearUserAsset, setUserAssets, collections, pickedCollection, populatePickedCollection } = useUserAsset();
  const [authError, setAuthError] = useState<AuthError | null>(null);

  const { isLoading, isRefetching, refetch, error, data } = useQuery(
    "organization",
    (): Promise<Organization> => OrganizationsAPI.getOrganization(krs, selectedDate),
    {
      refetchOnWindowFocus: false,
      enabled: false, // (!) handle refetchs manually
      keepPreviousData: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (isAuthorized) {
      FeatureStatusAPI.getStatus()
        .then((response) => {
          return {
            data: response.json(),
            status: response.status,
          };
        })
        .then((res) => {
          if (res.status !== 200) {
            setGusStatus(0);
          } else {
            res.data.then((gs) => {
              setGusStatus(gs.gusStatus);
            });
          }
        });
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (krsFetched) {
      setQueryError(error as AxiosError);
    }
  }, [error]);

  useEffect(() => {
    if (!isAuthorized) {
      setKrs("");
      setKrsFetched(false);
      setSelectedDate(undefined);
      clearUserAsset();
    } else {
      setUserAssets();
    }
  }, [isAuthorized]);

  const handleSearch = async (krs: string, date?: Date) => {
    await setKrs(krs);
    await setSelectedDate(date);
    // eslint-disable-next-line no-undef
    if (krs) {
      setKrsFetched(true);
      if (isRefreshTokenValid()) {
        setAuthError(null);
        refetch();
      } else {
        setAuthError({
          response: {
            status: 101,
          },
        });
      }
    }
  };

  const handleRegisterSwitch = async () => {
    await setKrsFetched(false);
    await setKrs("");
  };

  const handleInsertParagraph = async () => {
    let requestData: CollectionInstanceRequest = {
      parameters: {
        companyName: data.name,
        nipId: data.nip,
        krsId: data.krs,
        regonId: data.regon,
        companyAddressCity: data.address.city,
        companyHeadquaterCity: data.address.city,
        companyAddressCountry: data.address.country,
        companyAddressStreet: data.address.street,
        companyAddressStreetNumber: data.address.streetNumber,
        companyAddressApartment: data.address.apartment,
        companyAddressPostalCode: data.address.postalCode,
        courtOfLaw: data.courtOfLaw,
        companyFund: data.companyFund,
        companyFundCurrency: data.companyFundCurrency,
        companyFundPaid: data.companyFundPaid,
        companyFundPaidCurrency: data.companyFundPaidCurrency,
        companyRegisterFlag: data.companyRegisterFlag,
        companyOwnerFirstName: data.ownerFirstName,
        companyOwnerLastName: data.ownerLastName,
      },
    };

    let paragraphInsertData = await CollectionAPI.createCollectionTemplateInstance(
      requestData,
      pickedCollection.id,
      data.templateName
    )
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 201) {
          throw new Error("Could not fetch collection template instance");
        } else {
          return res.data.then((templateInstance) => {
            return templateInstance;
          });
        }
      });
    // eslint-disable-next-line no-undef
    await Word.run(async (context) => {
      let body = context.document.getSelection();
      // eslint-disable-next-line no-undef
      body.insertText(paragraphInsertData?.rawText.run, Word.InsertLocation.after);
      await context.sync();
    });
  };

  const handleDatePickerStateChange = () => {
    setKrsFetched(false);
  };

  const handlePickedCollection = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    populatePickedCollection(item.key as string);
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 150 },
  };

  function getDropdownLabels(collections: Collections): DropdownOptionItems {
    let result: DropdownOptionItems = [];
    if (collections) {
      result = collections.flatMap((item) => {
        return {
          key: item.id,
          text: item.metadata.uiprops.label ? item.metadata.uiprops.label : item.metadata.collectionName,
        };
      });
    }
    return result;
  }

  const options: IDropdownOption[] = [
    {
      key: "pickedHeader",
      text: LABELS_I18N.organizationPage.dropdown.dropdownOptions.pickedHeader,
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: pickedCollection?.id,
      text: pickedCollection?.metadata.uiprops.label || pickedCollection?.metadata.collectionName,
    },
    {
      key: "availableHeader",
      text: LABELS_I18N.organizationPage.dropdown.dropdownOptions.availableHeader,
      itemType: DropdownMenuItemType.Header,
    },
    ...getDropdownLabels(collections),
  ];

  const dropdownTokens: IStackTokens = {
    childrenGap: 8,
    maxWidth: "200px",
  };

  return (
    // @ts-ignore
    <Fragment>
      {!(gusStatus == 1) ? (
        <OrganizationSearch
          onSearch={handleSearch}
          onRegisterSwitch={handleRegisterSwitch}
          onDatePickerStateChange={handleDatePickerStateChange}
          query={krs}
          queryDate={selectedDate}
          disabled={isLoading || isRefetching}
        />
      ) : (
        <OrganizationSearchGus
          onSearch={handleSearch}
          onDatePickerStateChange={handleDatePickerStateChange}
          query={krs}
          queryDate={selectedDate}
          disabled={isLoading || isRefetching}
        />
      )}
      {(isLoading || isRefetching) && <Spinner />}
      {!(isLoading || isRefetching) && (queryError || authError) && (
        <OrganizationError error={authError || (queryError as AxiosError)} />
      )}
      {!isAuthorized && <OrganizationTutorial />}
      {isAuthorized && (
        <Fragment>
          {!(isLoading || isRefetching || error) && krsFetched && data?.dataIntegrityConcernFlag && (
            <MessageBar messageBarType={MessageBarType.warning}>
              <br /> {LABELS_I18N.organizationPage.messageBar.dataIntegrityConcernText.part1}
              <Link rel="noopener noreferrer" href="https://wyszukiwarka-krs.ms.gov.pl/" target="_blank">
                {LABELS_I18N.organizationPage.messageBar.dataIntegrityConcernText.part2}
              </Link>
            </MessageBar>
          )}
        </Fragment>
      )}

      {isAuthorized && krsFetched && (
        <Fragment>
          {!(isLoading || isRefetching || error) && (
            <table className="org-metadata-table">
              <tbody>
                {data?.name && (
                  <tr>
                    <td>Nazwa</td>
                    <td>{data?.name}</td>
                  </tr>
                )}
                {data?.krs && (
                  <tr>
                    <>
                      <td>KRS</td>
                      <td>{data?.krs}</td>
                    </>
                  </tr>
                )}
                {data?.nip && (
                  <tr>
                    <td>NIP</td>
                    <td>{data?.nip}</td>
                  </tr>
                )}
                {data?.regon && (
                  <tr>
                    <td>REGON</td>
                    <td>{data?.regon}</td>
                  </tr>
                )}
                <tr>
                  <td>Rejestr</td>
                  <td>{data?.source}</td>
                </tr>
                {data?.wayOfRepresentation && (
                  <tr>
                    <td>Sposób reprezentacji</td>
                    <td>{data?.wayOfRepresentation}</td>
                  </tr>
                )}
                {data?.headquater.voivodeship && (
                  <tr>
                    <td>Siedziba</td>
                    <td>{`${data?.headquater.voivodeship}, ${data?.headquater.community}`}</td>
                  </tr>
                )}
                {data?.address.postalCode && (
                  <tr>
                    <td>Adres</td>
                    <td>
                      {`${data?.address.postalCode} ${data?.address.city}, ${
                        data?.address.street ? data?.address.street : data?.address.city
                      } ${data?.address.streetNumber} ${
                        data?.address.apartment ? "lok. " + data?.address.apartment : ""
                      }`}
                    </td>
                  </tr>
                )}
                {data?.source == "KRS" && (
                  <tr>
                    <td>Sąd rejestrowy</td>
                    <td>{data?.source == "KRS" ? data?.courtOfLaw : ""}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </Fragment>
      )}
      {isAuthorized && (
        <Fragment>
          <div className="blink-actions">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={dropdownTokens}>
              <Stack.Item>
                <PrimaryButton
                  disabled={isLoading || isRefetching || !!error || !(data && krsFetched)}
                  text={LABELS_I18N.organizationPage.primaryButton.text}
                  onClick={handleInsertParagraph}
                />
              </Stack.Item>
              <Stack.Item>
                <Dropdown
                  placeholder={LABELS_I18N.organizationPage.dropdown.placeholder}
                  options={options}
                  styles={dropdownStyles}
                  onChange={handlePickedCollection}
                  disabled={isLoading || isRefetching || !!error || !(data && krsFetched)}
                />
              </Stack.Item>
            </Stack>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OrganizationPage;
