const FeatureStatusAPI = {
  baseURL: "https://api.justblink.com.pl",
  //baseURL: "http://localhost:3002",

  getStatus: (): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    return fetch(`${FeatureStatusAPI.baseURL}/status`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    });
  },
};

export default FeatureStatusAPI;
